<template>
  <v-navigation-drawer app permanent color="grey lighten-5" :mini-variant.sync="showLeftBarFlag">
    <template #prepend>
      <div class="d-flex align-center my-3">
        <!-- <v-btn icon class="ml-3" @click.stop="value = !value">
          <v-icon> mdi-close </v-icon>
        </v-btn>-->
        <!--      <v-btn-->
        <!--        icon-->
        <!--        :class="mini ? 'mx-auto' : 'ml-auto mr-2'"-->
        <!--        @click.stop="mini = !mini"-->
        <!--      >-->
        <!--        <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>-->
        <!--      </v-btn>-->
        <v-img
          contain
          width="50px"
          height="50px"
          min-width="50px"
          min-height="50px"
          src="@/assets/logo.png"
        ></v-img>
        <div v-if="!showLeftBarFlag" class="text-h6 flex-grow-1">金融数字员工</div>
      </div>
    </template>
    <v-list nav dense>
      <div v-for="(link, i) in menu" :key="i">
        <v-list-item
          v-if="!link.subs || !link.subs.length"
          :to="link.path"
          exact-active-class="primary transplate"
          class="v-list-item"
        >
          <v-list-item-icon class="mr-2">
            <v-icon>{{ link.meta.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title v-text="link.meta.title" />
        </v-list-item>

        <v-list-group
          v-if="link.subs"
          :key="link.meta.title"
          :prepend-icon="link.meta.icon"
          :value="link.collapse"
          active-class="primary white--text"
        >
          <template #activator>
            <v-list-item-title>{{ link.meta.title }}</v-list-item-title>
          </template>
          <template v-if="!showLeftBarFlag">
            <v-list-item
              v-for="sublink in link.subs"
              :key="sublink.meta.title"
              :to="sublink.path"
              class="primary--text"
              exact-active-class="primary transplate"
              @click="routeClick"
            >
              <v-list-item-title class="ml-9">
                <span class="mr-2 circle">&bull;</span>
                {{ sublink.meta.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
      </div>
    </v-list>
    <template #append>
      <div v-show="!showLeftBarFlag" class="mx-auto" style="width: 80%">
        <v-img src="@/assets/img_menu.png"></v-img>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      menu: [],
      showLeftBarFlag: false,
    };
  },
  computed: {
    ...mapState({
      routes: (state) => state.loginStore.routes,
    }),
  },
  watch: {
    $route() {
      const { checkRoute, initRouterMenu, refreshLinkCollapseStatus } = this;
      initRouterMenu(checkRoute());
      refreshLinkCollapseStatus();
      this.init()
    },
  },
  created() {
    this.init();
  },
  methods: {
    routeClick() {
      //调用msg
      this.$store.dispatch('getMsg');
    },
    checkRoute() {
      let routes = [];
      let routeMenus = {};

      this.$route.matched.forEach((item) => {
        if (item.name != 'home') {
          routes.push(item.name);
        }
      });

      if (routes != []) {
        if (routes.length == 2) {
          routeMenus = {
            selectMenu: `subMenu_${routes[0]}_${routes[1]}`,
            openKeys: `menu_${routes[0]}`,
          };
        } else if (routes.length == 1) {
          routeMenus = {
            selectMenu: `menu_${routes[0]}`,
            openKeys: '',
          };
        }
      }
      return routeMenus;
    },
    init() {
      const { routes, checkRoute, initRouterMenu } = this;
      const routeMenus = checkRoute();
      this.menu = routes;
      //有菜单
      if (this.menu.length > 0) {
        initRouterMenu(routeMenus);
      } else {
        //无菜单返回
      }
      this.refreshLinkCollapseStatus();
    },
    initRouterMenu(routeMenus) {
      const { $router, $route } = this;
      let firstMenu = this.menu[0];

      let isSub = firstMenu.subs.length > 0;
      let path = firstMenu.path;
      //判断是登录吗(当前路由不是home或者session获取不到)
      if ($route.name == 'home' || routeMenus == []) {
        //默认第一个菜单
        this.selectMenu = isSub
          ? 'subMenu_' + firstMenu.name + '_' + firstMenu.subs[0].name
          : 'menu_' + firstMenu.name;
        if (isSub) {
          this.openKeys = 'menu_' + firstMenu.name;
          path = firstMenu.subs[0].path;
        }
      } else {
        this.selectMenu = routeMenus.selectMenu;
        this.openKeys = routeMenus.openKeys;
        path = $route.path;
      }
      console.log($router,path)
       this.$forceUpdate()
      if (path != $route.path) {
        $router.push({ path});
      }
    },
    refreshLinkCollapseStatus() {
      // 当前画面如果属于某一菜单，初始化其父菜单为打开状态。
      let currentpath = this.$route.path;
      this.menu.forEach((link) => {
        if (link.subs && link.subs.length > 0) {
          if (
            link.subs.some((sublink) => {
              return currentpath === sublink.path;
            })
          ) {
            link.collapse = true;
          } else {
            link.collapse = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 14px;
}
>>> .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  min-width: auto;
  margin-right: 10px !important;
}
.transplate {
  color: white !important;
}
.circle {
  font-size: 25px;
  vertical-align: bottom;
}
</style>
